import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import * as serviceWorker from './serviceWorker';
// import { MsalProvider } from "@azure/msal-react";
// import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

const msalInstance = new PublicClientApplication(msalConfig);

// const AppProvider = () => (
// <MsalProvider instance={pca}>
//   <App />
//   </MsalProvider>);
// ReactDOM.render(<AppProvider />, document.getElementById("root")
// );

const AppProvider = () => (
  <MsalProvider instance={msalInstance}>
    {console.log("Came Here.......1")}
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={{
        scopes: ["User.Read"],
      }}
    >
      {console.log("Came Here .....2")}
      <App />
     </MsalAuthenticationTemplate>
  </MsalProvider>
);

ReactDOM.render(<App />, document.getElementById("root"));

/****************

 import { runWithAdal } from "react-adal";
 import { authContext } from "./adalConfig";

// Set DO_NOT_LOGIN to false so that authentication is ON
const DO_NOT_LOGIN = false;

// runWithAdal handles authentication using configurations defined in authContext
runWithAdal(
  authContext,
  () => {
    // eslint-disable-next-line
    require("./indexApp.js");
  },
  DO_NOT_LOGIN
);
 *****************/
